import React from "react"
import Layout from "../components/layout"
import InnerPageLayout from "../components/inner-page-layout"

export default function BootExchange() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Boot Exchange Programme</h1>
        <InnerPageLayout>
            <img src="boot-exchange.jpg" className="img-fluid mb-4 inner-page-hero" />
            <p className="lead">Do you have any football boots that no longer fit your child?</p>
            <p>Leeds City Juniors FC has set up a football boot exchange programme at all our events and as football brings communities together we would like to encourage all parents, residents and businesses to delve around in their attics, garages and cupboards to see if they can find old boots which can be given a new lease of life. With the community’s help we can do something worthwhile and increase young people’s physical activity levels across the town.</p>
        </InnerPageLayout>
      </section>
    </Layout>
  );
}
