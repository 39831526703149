import React from "react"
import ImageCard from "../components/image-card"
import LogoCard from "../components/logo-card"

export default function InnerPageLayout({children}) {
  return ( 
    <div className="row mb-2">
        <div className="col-md-9">
            {children}
        </div>
        <div className="col-md-3">

            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div className="col">
                    <ImageCard href="/policies" image="stock-respect2.jpg">Please see links to our clubs policies and other key documents.</ImageCard>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col">
                    <ImageCard href="/contact" image="stock-contactus.jpg">If you are interested in joining us, we are always ready to welcome new players, coaches or assistants.</ImageCard>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col">
                    <div className="card shadow-sm">
                    <div className="card-header">Featured Sponsor</div>
                    <img src="sponsor-kipmcgrath.jpg" />
                    <div className="card-body">
                        <p className="card-text">Kip McGrath provides live and interactive lessons online or in centre, tailored to your child’s needs.</p>
                        <div className="btn-group">
                        <a href="https://www.kipmcgrath.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                        </div>
                    </div>
                    </div>
                </div>    
            </div>

            <div className="row mb-4">
                <div className="col">
                    <LogoCard hideLogo={true} download={true} category="LCJ News" href="/LCJ Season Review 20_21.pdf" title="2020-2021 Season Review">Read the 2020-2021 season review</LogoCard>
                </div>
            </div>

        </div>
    </div>      
  );
}
