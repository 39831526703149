import React from "react"
import { Link } from "gatsby"

export default function LogoCard(props) {

    const linkHref = props.href || '/todo';
    const isDownload = props.download || false;

    return (
        <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <strong className="d-inline-block mb-2 lcj-color">{props.category}</strong>
                <h3 className="mb-0">{props.title}</h3>
                <p className="card-text mb-auto">{props.children}</p>
                { isDownload ?
                    (<a href={linkHref} target="_blank" className="stretched-link">Read more</a>)
                    :
                    (<Link to={linkHref} className="stretched-link">Read more</Link>)
                }
            </div>
            { !props.hideLogo && <div className="col-auto d-none d-lg-block">
                <img src="logo.svg" style={{maxWidth: '160px', margin: '0.5em', objectFit: 'cover'}} />
            </div>}
        </div>
    );
}
